import adminLayout from '@/layouts/Admin'
import httpAxios from '@/utils/http-axios.js'
import formatDateMixin from '@/mixins/format-date';
import paginationComponent from '@/components/Pagination'

export default {
    name: 'FairyTales',
    data() {
        return {
            fairyTales: [],
            totalFairyTales: 0
        }
    },
    mixins: [formatDateMixin],
    components: {
        adminLayout,
        paginationComponent
    },
    mounted() {
        this.getFairyTales()
    },
    methods: {
        pageChange: function (nr) {
            this.getFairyTales(nr);
        },
        getFairyTales(pageParamter = 1, limitParamter = 6) {
            const self = this;
            const limit = '?limit=' + limitParamter;
            const page = '&page=' + pageParamter;
            const withCount = '&withCount=pages';
    
            httpAxios({
                url: this.$backendUrl + '/api/v1/fairy-tales' + limit + page + withCount,
                method: 'GET'
            }).then(response => {
                self.fairyTales = response.data.rows;
                self.totalFairyTales = response.data.total_data;
            })
        }
    }
}
